
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    list: {
      type: Array,
    },
  },
  setup(props) {
    const data = reactive({
      list: [],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    watch(
      () => props.list,
      (newValue) => {
        getList(newValue);
      },
      { deep: true }
    );

    const getList = (list: any) => {
      data.list = list;
      console.log("???????????", data.list);
    };
    return {
      ...toRefs(data),
    };
  },
});
