
import PublicButton from "@/components/PublicButton/index.vue";
import Content from "./Content/index.vue";
import Loading from "@/components/Loading/index.vue";
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import { useStore } from "vuex";
import MyDialog from "@/components/Dialog/index.vue";
import { Plus } from "@element-plus/icons-vue";

import MySelect from "@/components/MySelect/index.vue";
import bus from "@/utils/bus";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import { da } from "element-plus/es/locale";
export default defineComponent({
  name: "",
  components: { MySelect, MyDialog, Loading, PublicButton, Content },
  setup() {
    const store = useStore();
    const Dialog = ref();
    const data = reactive({
      PopupModelId: "",
      PopupModelIdShow: false,
      echo: "",
      echo2: "",
      workpieceName: "",
      craftName: "",
      Show: true,
      EnableDisable: "",
      PopupId: <any>"", // 弹框展示不同内容id判断
      inputName: "",
      btnList: [
        { id: 1, name: "切换工件模式" },
        { id: 2, name: "当前禁用" },
        { id: 3, name: "删除当前工件" },
        { id: 4, name: "删除当前模式" },
        { id: 5, name: "新增工件" },
        { id: 6, name: "新增模式" },
        { id: 7, name: "修改当前工件" },
        { id: 8, name: "修改当前模式" },
      ],
      btnList2: <any>[],
      ContentList: <any>[],
      configData: {},
      btnList3: [
        { id: 9, name: "引用模板" },
        // { id: 10, name: "同步当前PLC" },
        // { id: 11, name: "同步全部PLC" },
        { id: 12, name: "保存" },
      ],
      WorkpieceList: <any>[],
      CraftList: <any>[],
      craftData: {
        findOnlyUsable: 0,
        produceItemCode: 100,
        projectId: store.state.projectId,
      },
      detailsData: {
        modelId: "484521328754294784",
        projectId: store.state.projectId,
      },
      AddWorkpiece: {
        itemName: "",
        projectId: store.state.projectId,
      },
      Addpattern: {
        modelName: "",
        produceItemCode: 0,
        projectId: store.state.projectId,
      },
      ModelButton: {
        button: 0,
        modelId: undefined,
        itemId: undefined,
        projectId: store.state.projectId,
      },
      // 编辑工件
      EditArtifactData: {
        itemId: 0,
        itemName: "",
        modifyType: 2,
        projectId: store.state.projectId,
      },
      // 编辑模式
      EditModeData: {
        modelId: 0,
        modelName: "",
        produceItemCode: 0,
        projectId: store.state.projectId,
      },
    });

    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getList();
    });
    const getList = () => {
      Api.commonPost(
        "production",
        "workpieceList",
        { projectId: store.state.projectId },
        (res: any) => {
          console.log(res);
          data.WorkpieceList = res.data.map((item: any, i: any) => {
            if (i == 0) {
              data.EditArtifactData.itemId = item.id;
              data.EditModeData.produceItemCode = item.itemCode;
              data.workpieceName = item.itemName;
              data.echo = item.itemCode;
              data.craftData.produceItemCode = item.itemCode;
              getModelUsable("");
            }
            return { id: item.id, value: item.itemCode, label: item.itemName };
          });
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    // 获取生产模式
    const getModelUsable = (id: any) => {
      Api.commonPost(
        "production",
        "ModelUsable",
        data.craftData,
        (res: any) => {
          data.CraftList = res.data.map((item: any, i: any) => {
            if (i == 0 && data.Show) {
              data.craftName = item.modelName;
              data.EditModeData.modelId = item.id;
              data.echo2 = item.id;
              data.detailsData.modelId = item.id;
              data.ModelButton.modelId = item.id;

              getTheSame(item);
              getJobMode();
            }
            // 启用禁止
            if (id == item.id) {
              getTheSame(item);
            }
            if (!data.Show) data.echo2 = "";
            return {
              id: item.id,
              label: item.modelName,
              value: item.id,
              usableStatus: item.usableStatus,
              typeName: item.usableStatus == 0 ? "禁用" : "启用",
              color: item.usableStatus == 0 ? "red" : "green",
              ...item,
            };
          });
          if (data.CraftList.length == 0) data.echo2 = "";
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };
    // 相同过滤条件方法
    const getTheSame = (item: any) => {
      data.btnList.forEach((it: any) => {
        if (it.id == 2) {
          let name = item.usableStatus == 0 ? "禁用" : "启用";
          it.name = `当前${name}`;
          data.EnableDisable = name == "启用" ? "禁用" : "启用";
        }
      });
    };

    // 获取详情
    const getJobMode = () => {
      let detailsData = JSON.parse(JSON.stringify(data.detailsData));
      if (data.PopupModelIdShow) {
        detailsData.modelId = data.PopupModelId;
      }
      Api.commonPost(
        "production",
        "JobMode",
        detailsData,
        (res: any) => {
          data.btnList2 = res.data.sendGroupList.map((item: any, i: any) => {
            return {
              id: item.sendGroupId,
              name: item.sendGroupName,
              highlight: i == 0 ? true : false,
              ...item,
            };
          });
          data.configData = res.data;
          getCut(data.btnList2[0].id);
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    // 点击触发弹框
    const getPopup = (item: any) => {
      data.PopupId = item.id;
      // 启用禁用
      if (item.id == 2) {
        data.ModelButton.button = data.EnableDisable == "启用" ? 1 : 2;
      }
      console.log(item);
      // 删除当前模式
      if (item.id == 4) {
        data.ModelButton.button = 3;
        data.ModelButton.itemId = undefined;
      }
      // 删除当前工件
      if (item.id == 3) {
        data.ModelButton.button = 3;
        data.ModelButton.modelId = undefined;
      }
      data.Addpattern.produceItemCode = data.craftData.produceItemCode; // 将原本模式下的id赋值给要添加的模式
      Dialog.value.getShow(item);

      if (item.id == 7) {
        //修改当前工件
        data.inputName = data.workpieceName;
      }
      if (item.id == 8) {
        // 修改当前模式
        data.inputName = data.craftName;
      }
    };

    // 底部按钮事件区域
    const getBase = (Data: any) => {
      Dialog.value.getShow(Data);
    };

    // 切换工件触发的事件
    const getChange = (e: any) => {
      data.echo = e;
      data.Show = false;
      data.detailsData.modelId = "";
      data.craftData.produceItemCode = e;
      data.Addpattern.produceItemCode = e;
      let id = data.WorkpieceList.filter((item: any) => item.value == e)[0].id;
      data.EditArtifactData.itemId = id;
      data.ModelButton.itemId = id;
      getModelUsable("");
    };
    // 切换工艺触发的事件
    const getChange2 = (e: any) => {
      //当等于引入模板的时候
      if (data.PopupId == 9) {
        data.PopupModelIdShow = true;
        data.PopupModelId = e;
      } else {
        data.PopupModelIdShow = false;
        data.echo2 = e;
        data.Show = false;
        data.EditModeData.modelId = e;
        data.detailsData.modelId = e;
      }
    };

    // 弹框确定按钮触发事件
    const getConfirm = () => {
      if (data.PopupId == 1) {
        if (!data.craftData.produceItemCode) {
          ElMessage({
            message: "请选择工件类型",
            type: "warning",
          });
          return;
        }

        if (!data.detailsData.modelId) {
          ElMessage({
            message: "请选择工艺模式",
            type: "warning",
          });
          return;
        }
        data.workpieceName = data.WorkpieceList.filter(
          (item: any) => item.value == data.craftData.produceItemCode
        )[0]?.label;
        let object = data.CraftList.filter(
          (item: any) => item.value == data.detailsData.modelId
        )[0];
        data.craftName = object.label;
        data.ModelButton.modelId = object.value;

        getTheSame(object);
        getJobMode();
      }

      if (data.PopupId == 5) {
        // 新增工件
        data.AddWorkpiece.itemName = data.inputName;
        if (!data.inputName) {
          ElMessage({
            message: "工件名称不得为空",
            type: "warning",
          });
          return;
        }
        Api.commonPost(
          "production",
          "CreateItem",
          data.AddWorkpiece,
          (res: any) => {
            ElMessage({
              message: "新增成功",
              type: "success",
            });
            data.inputName = "";
            getList();
          },
          (err: any) => {
            ElMessage.error(err.msg);
          }
        );
      }
      // 启用 禁用  删除当前模式
      if (data.PopupId == 2 || data.PopupId == 3 || data.PopupId == 4) {
        if (data.PopupId == 1 || data.PopupId == 2) {
          data.ModelButton.itemId = undefined;
        }
        Api.commonPost(
          "production",
          "ModelButton",
          data.ModelButton,
          (res: any) => {
            ElMessage({
              message: res.msg,
              type: "success",
            });
            //启用禁用才会触发
            if (data.PopupId == 2) {
              getModelUsable(data.ModelButton.modelId);
            }
            // 删除当前模式
            if (data.PopupId == 4 || data.PopupId == 3) {
              data.Show = true;
              getList();
            }
          },
          (err: any) => {
            ElMessage.error(err.msg);
          }
        );
      }
      // 新增模式
      if (data.PopupId == 6) {
        data.Addpattern.modelName = data.inputName;
        if (!data.inputName) {
          ElMessage({
            message: "工件模式名称不得为空",
            type: "warning",
          });
          return;
        }
        Api.commonPost(
          "production",
          "ModelNew",
          data.Addpattern,
          (res: any) => {
            ElMessage({
              message: "新增成功",
              type: "success",
            });
            data.inputName = "";
            getList();
          },
          (err: any) => {
            ElMessage.error(err.msg);
          }
        );
      }
      // 编辑工件
      if (data.PopupId == 7) {
        data.EditArtifactData.itemName = data.inputName;
        if (!data.inputName) {
          ElMessage({
            message: "工件名称不得为空",
            type: "warning",
          });
          return;
        }
        Api.commonPost(
          "production",
          "EditArtifact",
          data.EditArtifactData,
          (res: any) => {
            ElMessage({
              message: "修改成功",
              type: "success",
            });
            getList();
            data.inputName = "";
          },
          (err: any) => {
            ElMessage.error(err.msg);
          }
        );
      }
      // 修改当前模式
      if (data.PopupId == 8) {
        data.EditModeData.modelName = data.inputName;
        if (!data.inputName) {
          ElMessage({
            message: "工件模式名称不得为空",
            type: "warning",
          });
          return;
        }
        Api.commonPost(
          "production",
          "EditMode",
          data.EditModeData,
          (res: any) => {
            ElMessage({
              message: "修改成功",
              type: "success",
            });
            getModelUsable(data.EditModeData.modelId);
            data.inputName = "";
          },
          (err: any) => {
            ElMessage.error(err.msg);
          }
        );
      }

      // 当id等于9 为引用模板的时候

      if (data.PopupId == 9) {
        getJobMode();
      }
      // 保存
      if (data.PopupId == 12) {
        let requestData = {
          config: data.configData,
          modelId: data.detailsData.modelId,
          projectId: store.state.projectId,
        };
        Api.commonPost(
          "production",
          "ModelUpdate",
          requestData,
          (res: any) => {
            ElMessage({
              message: "保存成功",
              type: "success",
            });
          },
          (err: any) => {
            ElMessage.error(err.msg);
          }
        );
      }
      Dialog.value.getClose("close");
    };
    // 设备切换
    const getCut = (id: any) => {
      let list = data.btnList2.filter((item: any) => item.id == id)[0]
        .setupList;
      data.ContentList = list;
    };
    return {
      ...toRefs(data),
      Dialog,
      getPopup,
      getChange,
      getChange2,
      getConfirm,
      getCut,
      getBase,
    };
  },
});
